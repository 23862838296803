import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconExternalLink, IconSparkles } from "@tabler/icons-react";
import { useProfileStore } from "../../../../stores/UserStore";
import {
  findField,
  generateDatafieldContent,
} from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";
import TemperatureSlider from "../../../../components/View/TemperatureSlider";
import { useParams } from "react-router-dom";
import AddTokens from "../../../../components/Adder/AddTokens";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { getAIKeys } from "../../../../api/ConfigsApi";

const GenerateDatafieldContent = ({ current, onFinish }) => {
  const { contactListId } = useParams();
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [apiKeys, setApiKeys] = useState([]);
  const [loadingKeys, setLoadingKeys] = useState(false);

  const datafields = useConfigStore((s) => s.datafields);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    return df;
  }, [datafields]);

  const [datafield, setDatafield] = useState(current);
  const [finding, setFinding] = useState(false);
  const handleOpen = (e) => {
    open();
    setFinding(true);
    setLoadingKeys(true);
    e.stopPropagation();
  };

  useEffect(() => {
    if (finding) {
      findField(current.id)
        .then((res) => {
          setDatafield(res.data.data_field_profile);
        })
        .finally(() => setFinding(false));
    }
  }, [current.id, finding]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setError(null);
    setLoading(true);
    generateDatafieldContent({
      contact_list_id: parseInt(contactListId),
      datafield_id: current.id,
      prompt: data.prompt,
      language: data.language,
      temperature: parseFloat(data.temperature),
      external_api_key_id: parseInt(data?.external_api_key_id),
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.datafield_content.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .catch((err) => {
        let message = err.response.data.state_message;
        if (
          message === "You must provide at least one datafield in the prompt"
        ) {
          document.getElementById("ai_prompt").focus();
          setError(lang.errors.main.need_datafield);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    if (loadingKeys) {
      getAIKeys()
        .then((res) => {
          setApiKeys(
            res.data.external_api_keys_list?.filter((x) => x.type === "openai")
          );
        })
        .finally(() => setLoadingKeys(false));
    }
  }, [loadingKeys]);

  if (
    !profile.permissions?.find((x) => x.name === "generateDatafieldAi")
      ?.value === true
  ) {
    return "";
  }

  return (
    <>
      <Tooltip
        label={current?.ai_prompt || lang.contact_lists.datafield_content.label}
      >
        <ActionIcon variant="subtle" color="tertiary.8" onClick={handleOpen}>
          <IconSparkles size={18} />
        </ActionIcon>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          size={"lg"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.datafield_content.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
          styles={{
            title: {
              lineHeight: 1.5,
            },
          }}
        >
          <Stack
            component={"form"}
            onSubmit={handleSubmit}
            gap={"xs"}
            pos={"relative"}
          >
            <LoadingOverlay visible={finding} />

            <Text c={"dimmed"}>
              {lang.contact_lists.datafield_content.dialog.text}
            </Text>

            <Group pos={"relative"} wrap="nowrap" gap={0}>
              <Textarea
                label={lang.contact_lists.datafield_content.dialog.prompt}
                placeholder={
                  lang.contact_lists.datafield_content.dialog.prompt_placeholder
                }
                description={
                  lang.contact_lists.datafield_content.dialog.prompt_desc
                }
                defaultValue={datafield?.ai_prompt}
                name="prompt"
                id="ai_prompt"
                rows={4}
                w={"100%"}
                maxLength={500}
                required
                autoFocus
                data-autoFocus
                error={error}
              />
              <AddTokens
                items={datafieldTokens}
                handleAdd={(item) => {
                  let input = document.getElementById("ai_prompt");
                  input.value += `{${item?.value.replaceAll("%", "")}}`;
                  input.focus();
                }}
                long
                buttonProps={{
                  size: "xs",
                  variant: "subtle",
                  style: {
                    position: "absolute",
                    top: 4,
                    right: 0,
                  },
                }}
              />
            </Group>

            <GlobalLanguageSelector
              name={"language"}
              w="100%"
              size="sm"
              defaultValue={datafield?.ai_language}
            />

            <TemperatureSlider defaultValue={datafield?.ai_temperature} />

            <Group wrap="nowrap" align="end">
              <Select
                label={
                  lang.contact_lists.datafield_content.dialog.openai_api_key
                }
                placeholder={
                  lang.contact_lists.datafield_content.dialog.openai_api_key
                }
                flex={1}
                name="external_api_key_id"
                data={apiKeys.map((item) => ({
                  label: item.name,
                  value: item.id.toString(),
                }))}
                rightSection={loadingKeys ? <Loader size={16} /> : null}
              />

              <Button
                variant="light"
                component={"a"}
                href={`/extra/external-api-keys`}
                target="_blank"
                rightSection={<IconExternalLink size={18} />}
              >
                {lang.contact_lists.datafield_content.dialog.keys_list}
              </Button>
            </Group>

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconSparkles size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.datafield_content.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default GenerateDatafieldContent;
