import { useEffect, useMemo, useState } from "react";
import {
  createField,
  generateDatafieldContent,
  getFields,
} from "../../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { useDisclosure } from "@mantine/hooks";
import {
  Button,
  Group,
  Loader,
  Menu,
  Modal,
  Select,
  Stack,
  Textarea,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import AddTokens from "../../../../../components/Adder/AddTokens";
import GlobalLanguageSelector from "../../../../../components/Selectors/GlobalLanguageSelector";
import TemperatureSlider from "../../../../../components/View/TemperatureSlider";
import { useParams } from "react-router-dom";
import { IconExternalLink, IconSparkles } from "@tabler/icons-react";
import { getAIKeys } from "../../../../../api/ConfigsApi";

const CreateFieldAI = ({ onFinish }) => {
  const { contactListId } = useParams();
  const lang = useLanguage((s) => s.language);
  const fields = useConfigStore((s) => s.datafields);

  const datafieldTokens = useMemo(() => {
    let df = fields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    return df;
  }, [fields]);

  const [opened, { open, close }] = useDisclosure();
  const [creatingDatafield, setCreatingDatafield] = useState(false);
  const [error, setError] = useState(false);

  const [apiKeys, setApiKeys] = useState([]);
  const [loadingKeys, setLoadingKeys] = useState(false);
  const createDatafield = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    if (fields.some((x) => x.name.toLowerCase() === data?.name.toLowerCase())) {
      return setError("name");
    }
    setCreatingDatafield(true);
    createField({ name: data?.name }).then((res) => {
      notifications.show({
        message: lang.datafields.row_highlight.created,
        color: "tertiary.8",
      });

      generateDatafieldContent({
        contact_list_id: parseInt(contactListId),
        datafield_id: res.data.data_field_id,
        prompt: data.prompt,
        language: data.language,
        temperature: parseFloat(data.temperature),
        external_api_key_id: parseInt(data?.external_api_key_id),
      })
        .then((res) => {
          notifications.show({
            message: lang.contact_lists.datafield_content.success,
            color: "tertiary.8",
          });

          getFields()
            .then(() => {
              onFinish?.({ data_field_id: res.data.data_field_id });
              close();
            })
            .finally(() => setCreatingDatafield(false));
        })
        .catch((err) => {
          setCreatingDatafield(false);
          let message = err.response.data.state_message;
          if (
            message === "You must provide at least one datafield in the prompt"
          ) {
            document.getElementById("ai_prompt").focus();
            setError("prompt");
          }
        });
    });
  };

  useEffect(() => {
    if (opened) {
      setLoadingKeys(true);
      getAIKeys()
        .then((res) => {
          setApiKeys(
            res.data?.external_api_keys_list?.filter((x) => x.type === "openai")
          );
        })
        .finally(() => setLoadingKeys(false));
    }
  }, [opened]);

  return (
    <>
      <Menu.Item onClick={open}>
        <Group gap={4}>
          <ThemeIcon variant="subtle" p={0}>
            <IconSparkles size={18} />
          </ThemeIcon>
          {lang.contact_lists.create_column.label_ai}
        </Group>
      </Menu.Item>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.contact_lists.create_column.title}
          centered
          size={"lg"}
        >
          <Stack component={"form"} onSubmit={createDatafield} gap={"xs"}>
            <TextInput
              name="name"
              required
              label={lang.contact_lists.create_column.label}
              placeholder={lang.contact_lists.create_column.placeholder}
              data-autofocus
              onChange={(e) => {
                setError(false);
              }}
              error={
                error === "name" ? lang.contact_lists.create_column.error : null
              }
            />

            <Group pos={"relative"} wrap="nowrap" gap={0}>
              <Textarea
                label={lang.contact_lists.datafield_content.dialog.prompt}
                placeholder={
                  lang.contact_lists.datafield_content.dialog.prompt_placeholder
                }
                description={
                  lang.contact_lists.datafield_content.dialog.prompt_desc
                }
                name="prompt"
                id="ai_prompt"
                rows={4}
                w={"100%"}
                maxLength={500}
                required
                autoFocus
                data-autoFocus
                error={
                  error === "prompt" ? lang.errors.main.need_datafield : null
                }
              />
              <AddTokens
                items={datafieldTokens}
                handleAdd={(item) => {
                  let input = document.getElementById("ai_prompt");
                  input.value += `{${item?.value.replaceAll("%", "")}}`;
                  input.focus();
                }}
                long
                buttonProps={{
                  size: "xs",
                  variant: "subtle",
                  style: {
                    position: "absolute",
                    top: 4,
                    right: 0,
                  },
                }}
              />
            </Group>

            <GlobalLanguageSelector
              name={"language"}
              w="100%"
              size="sm"
              mb={4}
            />

            <TemperatureSlider />

            <Group wrap="nowrap" align="end">
              <Select
                label={
                  lang.contact_lists.datafield_content.dialog.openai_api_key
                }
                placeholder={
                  lang.contact_lists.datafield_content.dialog.openai_api_key
                }
                flex={1}
                name="external_api_key_id"
                data={apiKeys.map((item) => ({
                  label: item.name,
                  value: item.id.toString(),
                }))}
                disabled={loadingKeys}
                rightSection={loadingKeys ? <Loader size={16} /> : null}
              />

              <Button
                variant="light"
                component={"a"}
                href={`/extra/external-api-keys`}
                target="_blank"
                rightSection={<IconExternalLink size={18} />}
              >
                {lang.contact_lists.datafield_content.dialog.keys_list}
              </Button>
            </Group>

            <Group justify="end" mt={"md"}>
              <BackButton onClick={close} />
              <Button type="submit" loading={creatingDatafield}>
                {lang.datafields.create.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CreateFieldAI;
