import { useEffect, useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Group, Text } from "@mantine/core";
import { extractDomainFromUrl } from "../../api/ResellerAPI";

const versionMetaTagRegex = /<meta\s+name="version"\s+content="(\d+)"\s*\/>/;

const VersionController = () => {
  const location = useLocation();
  const lang = useLanguage((s) => s.language);
  const [needReload, setNeedReload] = useState(false);

  const handleRefresh = () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  useEffect(() => {
    setNeedReload(false);
    let actualVersion = document.querySelector("meta[name='version']")?.content;

    axios
      .get(
        `https://${extractDomainFromUrl(
          window.location.href
        )}/index.html?timestamp=${new Date().getTime()}&actual_version=${actualVersion}`
      )
      .then((res) => {
        let newVersion = res.data.match(versionMetaTagRegex)[1];

        if (newVersion) {
          if (parseInt(newVersion) > parseInt(actualVersion)) {
            setNeedReload(true);
          }
        } else {
          setNeedReload(false);
        }
      });
  }, [location]);

  if (needReload)
    return (
      <Group
        justify="center"
        p={8}
        px={12}
        style={{ borderRadius: 6 }}
        bg={"secondary.7"}
        ml={"auto"}
        mr={"auto"}
      >
        <Text c={"white"} fz={13}>
          ✨ {lang.header.alert.text1}{" "}
          <a
            onClick={handleRefresh}
            style={{
              fontWeight: "600",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {lang.header.alert.refresh}
          </a>{" "}
          {lang.header.alert.text2} ✨
        </Text>
      </Group>
    );
  return "";
};

export default VersionController;
